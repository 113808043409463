@value constants: "~/constants.css";
@value primaryLightest from constants;
@value offWhite from constants;
@value tertiaryLight from constants;
@value boldBorderWidth from constants;

.card {
    composes: boldBorder from '~/commonClasses.css';
    background-color: offWhite;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    position: relative;
}

.activeCard {
    composes: card;
}

.card.activeCard {
    border-left-width: 1rem;
    padding-left: 0.2rem;
    border-color: tertiaryLight;
}

.cardInterior {
    composes: pure-g from global;
    width: 100%;
    box-sizing: border-box;
}

.name {
    composes: pure-u-1 from global;
}

.cardElement {
    box-sizing: border-box;
    padding: 0.4rem;
}

.numberField {
    composes: pure-u-1 from global;
    composes: pure-u-sm-1-2 from global;
    composes: pure-u-md-1-3 from global;
    composes: cardElement;
    min-height: 2.5rem;
}

.initiative {
    composes: numberField;
}

.health {
    composes: numberField;
}

.armorClass {
    composes: numberField;
}

.notes {
    composes: pure-u-1 from global;
    /* composes: pure-u-sm-1-2 from global;
    composes: pure-u-md-1 from global; */
}

.buttons {
    composes: pure-u-1 from global;
    composes: cardElement;
}

.close {
    position: absolute;
    top: 0.2rem;
    right: 0.4rem;
}

.notesBtn {
    position: absolute;
    top: 0.2rem;
    right: 1.7rem;
}
