@value constants: "~/constants.css";
@value sansFont from constants;

.nameInput {
    font-size: 2rem;
    box-sizing: border-box;
    background-color: transparent;
    border-width: 0;
    border-color: transparent;
    font-family: sansFont;
    font-weight: 600;
}

.nameInputContainer {
    font-size: 2rem;
    font-family: sansFont;
    font-weight: 600;
}
