@value offWhite from "~/constants.css";

:global {
    @import 'purecss/build/pure.css';
    @import 'purecss/build/grids-responsive.css';
}

:global body {
    min-height: 100vh;
    min-width: 100vw;
}

:global #app {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    background-color: offWhite;
}

:global html {
    overflow-x: hidden;
}
