@value constants: "~/constants.css";
@value primaryLight, offWhite, sansFont from constants;

.header {
    composes: pure-g from global;
    background-color: primaryLight;
    padding-top: 2em;
    padding-bottom: 2em;
    flex: 0;
}

.spacing {
    composes: pure-u-1-24 from global;
    composes: pure-u-md-1-5 from global;
}

.titles {
    composes: pure-u-23-24 from global;
    composes: pure-u-md-4-5 from global;
}

.title {
    font-family: sansFont;
    color: offWhite;
    font-weight: bold;
    margin: 0;
    padding: 0;
    font-size: 3em;
}

.subtitle {
    font-family: sansFont;
    color: offWhite;
    margin: 0;
    padding: 0;
    font-size: 1.25em;
}

@media screen and (max-width: maxPhoneWidth) {
    .header {
    }

    .title {
    }

    .subtitle {
    }
}
