@value constants: "~/constants.css";
@value offBlack, tertiary, tertiaryLightest, primary from constants;

.btn {
    background-color: transparent;
    border-width: 0;
    border-color: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.icon:not(.activeIcon) {
    color: offBlack;
}

.activeIcon {
    color: primary;
}

.btn:focus .icon {
    color: tertiaryLightest;
}

.btn:hover .icon,
.icon:hover {
    color: tertiary;
}
