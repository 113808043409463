@value sansFont from "~/constants.css";
@value primaryDark from "~/constants.css";
@value tertiaryLight from "~/constants.css";
@value tertiaryLightest from "~/constants.css";
@value primaryLightest from "~/constants.css";
@value offWhite from "~/constants.css";
@value offBlack from "~/constants.css";

@value flashColor: tertiaryLight;

.container {
    composes: boldBorder from '~/commonClasses.css';
    width: 100%;
    max-width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: offWhite;

    padding: 0.3rem;
}

.container:not(.flashingContainer) {
    border-color: offBlack;
}

.flashingContainer {
    composes: container;
    border-color: flashColor;
}

.container.flashingContainer {
    border-width: 0.3rem;
    padding: 0.2rem;
}

.input {
    align-self: stretch;
    border: 0;
    flex-grow: 1;
    flex-shrink: 1;
    width: 1rem;
    font-family: sansFont;
    font-size: 1.5rem;
    font-weight: bold;
    padding-left: 0.4rem;
    background-color: transparent;
}

.flashingContainer .input {
    color: flashColor;
}
