@value constants: "~/constants.css";
@value primaryLight, primaryLightest, offWhite from constants;

.btn {
    composes: pure-button from global;
    composes: pure-button-primary from global;
    border-radius: 0.5em;
    color: offWhite;
    background-color: primaryLight;
    width: 100%;
}

.icon {
}
